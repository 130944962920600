import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../entity-types';

export class EventInvitation extends WarpEntity {
  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.EventInvitation;
  }

  static empty(): EventInvitation {
    return new EventInvitation(super.emptyEntity(EntityTypes.EventInvitation));
  }
}
