import { RRule, RRuleSet, rrulestr } from 'rrule';

export interface Event {
    entityId: number;
    id?: number | string;
    title?: string;
    start?: string;
    end?: string;
    daysOfWeek?: string[];
    startTime?: string;
    endTime?: string;
    startRecur?: string;
    endRecur?: string;
    resourceIds?: number[];
    color?: string;
    textColor?: string;
    rrule?: string;
    duration?: string;
    today?: string;
}

export const weekdays = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];

export interface CalendarRange {
    start: Date;
    end: Date;
}

