import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService, MessageService, RippleApiService } from '@ripple/services';
import * as moment_ from 'moment';
const moment = moment_;
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormScoresService extends RippleApiService {
  private restURL = '/api/emr/report/formscores';

  constructor(http: HttpClient, authService: AuthService, messageService: MessageService) {
    super(http, messageService, authService);
  }

  public getReport(
    startDate: Date,
    endDate: Date,
    formTypes: number[],
    clients: number[],
    forms: number[],
    clientTypeId: number = null,
    includeContactForms = false
  ): Observable<any> {
    const body = {
      startDate: !startDate ? null : moment(startDate).format('YYYY-MM-DD'),
      endDate: !endDate ? null : moment(endDate).format('YYYY-MM-DD'),
      formTypes,
      clients: !clients || clients.length === 0 ? null : clients,
      forms: !forms || forms.length === 0 ? null : forms,
      clientType: clientTypeId,
      includeContactForms,
    };

    return this._post(this.restURL, body);
  }
}
