import { environment } from '@ripple/environment';
import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../entity-types';
import { util } from '@ripple/core';
import { Event } from './_event';

import * as moment_ from 'moment';
const moment = moment_;
import { GenericCalendarEvent } from '..';

export class TimeSlot extends WarpEntity implements Event {

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.TimeSlot;
    this.properties.starttime = moment(this.properties.starttime, 'hh:mm A').format('HH:mm');
    this.initEvent();
  }

  initEvent() {
    this.title = this.name;
    this.start = moment(this.properties.date + ' ' + this.properties.starttime).format('YYYY-MM-DDTHH:mm:SS');
    this.end = moment(this.start).add(this.properties.length, 'm').format('YYYY-MM-DDTHH:mm:SS');
    this.resourceIds = [];
    if (this.properties.workers && this.properties.workers[0])
      this.resourceIds.push(this.properties.workers[0].id);
    //this.color = this.entityId < 0 ? '#ada8a8' : '#AFD3C8';
    const eventTypeStyling = environment.eventTypeStyling[this.entityTypeId];
    this.color = this.entityId < 0 ? eventTypeStyling.newAddedColor : eventTypeStyling.bgColor;
    this.textColor = eventTypeStyling.textColor;
  }

  generateFromGenericCalendarEvent(event: GenericCalendarEvent) {
    this.properties.workers = event.properties.workers;
    this.properties.date = event.properties.date;
    this.properties.length = event.properties.length;
    this.properties.starttime = event.properties.starttime;
  }

  static empty(): TimeSlot {
    return new TimeSlot(super.emptyEntity(EntityTypes.TimeSlot));
  }

  static random(): TimeSlot {
    const e = TimeSlot.empty();
    e.properties['name'] = `${util.names({ seed: new Date() })}`;
    e.properties['color'] = util.color('name');
    return e;
  }

  static monthlyTitle(timeSlot: WarpEntity) {
    return `${timeSlot.properties.starttime} for ${timeSlot.properties.length} minutes`;
  }
}
