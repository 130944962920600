import { Routes } from '@angular/router';
import { RippleUserGuard } from '@ripple/services';
// import { ExternalAuthComponent } from '@ripple/ui';
import { LoginComponent } from '../common/login/login.component';
import { HomePageComponent } from '../common/home-page/home-page.component';
import { PrintComponent } from '../clinical/pages/print/print.component';

export const routes: Routes = [
  // {
  //   path: 'token/:tokenGuid',
  //   component: ExternalAuthComponent,
  // },
  {
    path: 'settings',
    canActivate: [RippleUserGuard],
    loadChildren: () => import('../common/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'login',
    component: LoginComponent,
    loadChildren: () => import('../common/common.module').then(m => m.AppCommonModule)
  },
  {
    path: 'clinical',
    canActivate: [RippleUserGuard],
    loadChildren: () => import('../clinical/clinical.module').then(m => m.ClinicalModule)
  },
  {
    path: 'education',
    canActivate: [RippleUserGuard],
    loadChildren: () => import('../education/education.module').then(m => m.EducationModule)
  },
  {
    path: 'administration',
    canActivate: [RippleUserGuard],
    loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'paces',
    canActivate: [RippleUserGuard],
    loadChildren: () => import('../paces/paces.module').then(m => m.PACESModule)
  },
  {
    path: 'csart',
    canActivate: [RippleUserGuard],
    loadChildren: () => import('../csart/csart.module').then(m => m.CSARTModule)
  },
  {
    path: 'crisis',
    canActivate: [RippleUserGuard],
    loadChildren: () => import('../crisis/crisis.module').then(m => m.CRISISModule)
  },
  {
    path: 'microsite',
    loadChildren: () => import('../microsite/microsite.module').then(m => m.MicrositeModule)
  },
  {
    path: 'events',
    loadChildren: () => import('../microsite/microsite.module').then(m => m.MicrositeModule)
  },
  {
    path: 'kiosk',
    loadChildren: () => import('../kiosk/kiosk.module').then(m => m.KioskModule)
  },
  {
    path: '',
    // canActivate: [RippleUserGuard],
    component: HomePageComponent,
  },
  { path: '**', redirectTo: '' },
];
