import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../entity-types';


export class CCMAggregateReport extends WarpEntity {
  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.CCMAggregateReport;
  }

  static empty(): CCMAggregateReport {
    return new CCMAggregateReport(super.emptyEntity(EntityTypes.CCMAggregateReport));
  }
}
