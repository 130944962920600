import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../entity-types';
import { util } from '@ripple/core';

import * as moment_ from 'moment';
const moment = moment_;

export class EventFolder extends WarpEntity {

  constructor(obj) {
    super(obj);
    if (!this.properties.attendees) this.properties.attendees = [];
    this.modify_preSave(true, EventFolder.checkIfAttendeesEmptyArr);
  }

  static empty(): EventFolder {
    return new EventFolder(super.emptyEntity(EntityTypes.EventFolder));
  }

  static random(): EventFolder {
    const e = EventFolder.empty();
    return e;
  }

  static checkIfAttendeesEmptyArr(w: WarpEntity): boolean {
    if (w.properties && w.properties.attendees && w.properties.attendees.length === 0) w.properties.attendees = null;
    return true;
  }
}
