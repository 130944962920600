import { Observable } from 'rxjs';
import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../../entity-types';
import { ClientFileTemplateSession } from './client-file-template-session';
import { environment } from 'subscribers/ccasa-emr/src/environments/environment';

export const ClientFileTemplates = environment.clientFileTemplates;

export class ClientFileTemplate extends WarpEntity {
  sessions: Observable<Map<number, ClientFileTemplateSession>>;
  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.ClientFileTemplate;
  }

  static empty(): ClientFileTemplate {
    return new ClientFileTemplate(super.emptyEntity(EntityTypes.ClientFileTemplate));
  }
}
