import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../entity-types';
import { util } from '@ripple/core';

import * as moment_ from 'moment';
const moment = moment_;

export class EventTemplate extends WarpEntity {

  constructor(obj) {
    super(obj);
    // init available forms
    if (this.properties.availableforms)
      if (this.properties.availableforms instanceof Array)
        this.properties.availableforms = this.properties.availableforms.map(af => parseInt(af.value, 10));
      else if (typeof this.properties.availableforms === 'string')
        this.properties.availableforms = [parseInt(this.properties.availableforms, 10)];

    if (this.properties.autocreateforms)
      if (this.properties.autocreateforms instanceof Array)
        this.properties.autocreateforms = this.properties.autocreateforms.map(af => parseInt(af.value, 10));
      else if (typeof this.properties.autocreateforms === 'string')
        this.properties.autocreateforms = [parseInt(this.properties.autocreateforms, 10)];

    if (this.properties.availableformsfacilitator)
      if (this.properties.availableformsfacilitator instanceof Array)
        this.properties.availableformsfacilitator = this.properties.availableformsfacilitator.map(af => parseInt(af.value, 10));
      else if (typeof this.properties.availableformsfacilitator === 'string' )
        this.properties.availableformsfacilitator = [parseInt(this.properties.availableformsfacilitator, 10)];

    if (this.properties.autocreateformsfacilitator)
      if (this.properties.autocreateformsfacilitator instanceof Array)
        this.properties.autocreateformsfacilitator = this.properties.autocreateformsfacilitator.map(af => parseInt(af.value, 10));
      else if (typeof this.properties.autocreateformsfacilitator === 'string')
        this.properties.autocreateformsfacilitator = [parseInt(this.properties.autocreateformsfacilitator, 10)];

    // init required workers and volunteers
    if (!this.properties['required#workers']) this.properties['required#workers'] = 0;
    if (!this.properties['required#volunteers']) this.properties['required#volunteers'] = 0;
  }

  static empty(): EventTemplate {
    return new EventTemplate(super.emptyEntity(EntityTypes.EventTemplate));
  }

  static random(): EventTemplate {
    const e = EventTemplate.empty();
    return e;
  }
}
