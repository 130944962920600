import { WarpEntity } from '@ripple/models';
import { Person } from './_person';
import { EntityTypes } from '../entity-types';

import { environment } from '@ripple/environment';

export const ContactTypes = {
    ATTENDEE: { id: 1278, optionName: 'Attendee' },
    UNDERAGE_ATTENDEE: { id: 1280, optionName: 'Underage Attendee' },
    TEACHER: { id: 1281, optionName: 'Teacher' },
    SUPERVISOR: {id: 1282, optionName: 'Supervisor' },
    VOLUNTEER: { id: 1283, optionName: 'Volunteer' },
    EVENT_REQUESTOR: { id: 1284, optionName: 'Event Requestor' }
}

export class Contact extends WarpEntity implements Person {
    id: string | number;
    //img = 'assets/img/anonymousUser.png';
    firstname: string;
    lastname: string;

    constructor(obj) { // direct conversion from
        super(obj);
    }

    get displayName(): string {
        return `${this['last name']}, ${this['first name']}`;
    }

    get img(): string {
        return 'assets/img/anonymousUser.png';
    }

    get initials(): string {
        return (this['first name'] ? this['first name'].charAt(0) : '')
                + (this['last name'] ? this['last name'].charAt(0) : '');
    }

    static empty(): Contact {
        return new Contact(super.emptyEntity(EntityTypes.Contact));
    }
}
