
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService, MessageService, RippleApiService } from '@ripple/services';

@Injectable({
  providedIn: 'root',
})
export class FormBuilderReportService extends RippleApiService {
  private restURL = '/api/emr/report';

  constructor(http: HttpClient, authService: AuthService, messageService: MessageService) {
    super(http, messageService, authService);
  }

  public getReport(dateCompletedRange = [], clients = [], workers = [], statuses = [], formFilters = [], extraFilters = [], includeContactForms = false, isEducation = false, extraFormFilters = []): Promise<any> {
    const body = {
      dateCompletedRange,
      clients,
      workers,
      statuses,
      formFilters,
      extraFilters,
      includeContactForms,
      isEducation,
      extraFormFilters
    }

   return this._post(this.restURL.concat('/entitytype/636'), body).toPromise();
  }

  public getClientTypesForReport(isEducation) {
    return this._get(this.restURL.concat('/getclienttypesforreport/' + isEducation.toString())).toPromise();
  }

  public getFileTypesForReport() {
    return this._get(this.restURL.concat('/getfiletypesforreport/')).toPromise();
  }

  public getClientSessionTemplatesForReport() {
    return this._get(this.restURL.concat('/getclientsessiontemplatesforreport/')).toPromise();
  }

  public getSessionData(startDate = null, endDate = null, clients = [], workers = [], extraFilters = [], isEducation = false): Promise<any> {
    const body = {
      startDate,
      endDate,
      clients,
      workers,
      extraFilters,
      isEducation
    }

    return this._post(this.restURL.concat('/sessiondata/'), body).toPromise();
  }

}

