import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../entity-types';

export class ServiceLocation extends WarpEntity {
  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.ServiceLocation;
  }

  static empty(): ServiceLocation {
    return new ServiceLocation(super.emptyEntity(EntityTypes.ServiceLocation));
  }
}
