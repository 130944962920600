import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService, MessageService, RippleApiService } from '@ripple/services';

@Injectable({
  providedIn: 'root',
})
export class WaitListReportService extends RippleApiService {
  private restURL = '/api/ccasa/waitlist';

  constructor(http: HttpClient, authService: AuthService, messageService: MessageService) {
    super(http, messageService, authService);
  }

  public getReport(): Promise<any> {
   return this._get(this.restURL.concat("/report")).toPromise();
  }
}
