import { Injectable, Injector } from '@angular/core';
import { WarpEntityServiceCache, WarpEntityCacheFactoryService, RippleApiService } from '@ripple/services';
import { EntityTypes, ClientFileTemplateSession, Session, User, ClientFileTemplateForm, ClientType } from '../../models';
import { EntityFilter, FilterOperator } from '@ripple/models';
import { first, map } from 'rxjs/operators';
import * as moment_ from 'moment';
const moment = moment_;
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionServiceCache extends WarpEntityServiceCache<Session> {
  templateService: WarpEntityServiceCache<ClientFileTemplateSession>;

  constructor(injector: Injector) {
    super(injector, Session, EntityTypes.Session);
    const cacheFactory = this.injector.get(WarpEntityCacheFactoryService);
    this.templateService = cacheFactory.get(EntityTypes.ClientFileTemplateSession) as WarpEntityServiceCache<ClientFileTemplateSession>;
  }

  getSessionTemplateById(id: number): Observable<ClientFileTemplateSession> {
    return this.templateService.get(id);
  }

  getPendingPaperwork(clientType = '') {
    return this.warpService
      ._get('/api/ccasa/overview/pending-paperwork?detailed=true&clientType=' + clientType, 'Get Pending Paperwork')
      .pipe(map((m) => m.map((e) => new Session(e))));
  }

  getUpcomingSessions(worker?: User, num?: number) {
    const today = new Date();

    const filter = EntityFilter.Advanced({
      key: 'date',
      operator: FilterOperator.GreaterThanOrEqualTo,
      value: moment().format('YYYY/MM/DD'),
    }).orderBy('date', 'asc');

    if (worker) filter.Advanced({ workers_lid: worker.entityId });
    if (!num) num = 1;

    return this.initQuery(filter.only(num)).getPage(0);
  }

  // This should no longer be used anywhere.  Switched occurance of it to clientFileTemplateSessionService: ClientFileTemplateSessionCache -- getById
  // instead which also loads all child ClientFileTemplateForms
  // getSessionTemplate(type: string, templateId?: number) {
  //   const filter = templateId
  //     ? EntityFilter.None.in(templateId)
  //     : EntityFilter.Advanced({ sessionType: type, isDefault: 'Yes' });

  //   return this.templateService.getPage(filter.loadChildren(EntityTypes.ClientFileTemplateForm), false).pipe(
  //     map((m) => {
  //       if (m.size < 1) throw Error('There must be a Default Session Template for each session type.');

  //       const s = [...m.values()].sort((ft) => ft.parentIds[0])[0];
  //       s.childEntities = s.childEntities.map((f) => new ClientFileTemplateForm(f));
  //       return s as Omit<ClientFileTemplateSession, 'childEntities'> & { childEntities: ClientFileTemplateForm[] };
  //     })
  //   );
  // }

  checkInClient(client: any) {
    return this.warpService._post('/api/ccasa/kiosk/checkin', client);
  }
}
