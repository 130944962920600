import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { SCREEN_SIZE, ScreenSizeService } from '../../_core/services/screen-size/screen-size.service';
import { Subject, Observable } from 'rxjs';

import { debounceTime } from 'rxjs/operators';
import { BasePage } from '@ripple/core';
import { WarpEntityLogService } from '@ripple/services';


@Component({
  selector: 'ccasa-screen-size-detector',
  templateUrl: './screen-size-detector.component.html',
  styleUrls: ['./screen-size-detector.component.scss']
})
export class ScreenSizeDetectorComponent extends BasePage implements OnInit {
  get componentName(): string {
    return 'ScreenSizeDetectorComponent';
  }

  prefix = 'is-';
  sizes = [
    {
      id: SCREEN_SIZE.XS, name: 'xs', css: `d-block d-sm-none`
    },
    {
      id: SCREEN_SIZE.SM, name: 'sm', css: `d-none d-sm-block d-md-none`
    },
    {
      id: SCREEN_SIZE.MD, name: 'md', css: `d-none d-md-block d-lg-none`
    },
    {
      id: SCREEN_SIZE.LG, name: 'lg', css: `d-none d-lg-block d-xl-none`
    },
    {
      id: SCREEN_SIZE.XL, name: 'xl', css: `d-none d-xl-block`
    },
  ];

  resize$ = new Subject();

  @HostListener("window:resize", [])
  private onResize() {
    this.resize$.next();
  }

  constructor(private elementRef: ElementRef, private screenSizeService: ScreenSizeService, logService: WarpEntityLogService) {
    super(logService);
    
    this.resize$.pipe(
      debounceTime(1000)
    ).subscribe(() => {
      this.detectScreenSize();
    });
  }

  ngAfterViewInit() {
    this.detectScreenSize();
    this.resize$.next();
  }

  ngOnInit(): void {
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string): string {
    return '';
  }

  private detectScreenSize() {
    const currentSize = this.sizes.find(x => {
      // get the HTML element
      const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${x.id}`);

      // check its display property value
      const isVisible = window.getComputedStyle(el).display !== 'none';

      return isVisible;
    });

    this.screenSizeService.onResize(currentSize.id);
  }
}
