import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../entity-types';

export enum WaitingListStatus {
  ON_WAITLIST = 1895,
  ADDED_TO_FILE = 1896,
  DELETED_FROM_WAITLIST = 1897,
}

export class WaitingList extends WarpEntity {
  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.WaitingList;
  }

  static empty(): WaitingList {
    return new WaitingList(super.emptyEntity(EntityTypes.WaitingList));
  }
}
