import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../../entity-types';

export class ClientFileTemplateSession extends WarpEntity {
  indexForType: number;

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.ClientFileTemplateSession;
  }

  static empty(): ClientFileTemplateSession {
    return new ClientFileTemplateSession(super.emptyEntity(EntityTypes.ClientFileTemplateSession));
  }

  get default(): boolean {
    return (typeof this.isdefault !== 'undefined' && typeof this.isdefault.id !== 'undefined' && this.isdefault.id === 333);
  }

  get index(): number {
    return this.itemorderindex;
  }

  get countsTowardsProgress(): boolean {
    // Default: No
    return (typeof this.countstowardsprogress !== 'undefined' && typeof this.countstowardsprogress.id !== 'undefined' && this.countstowardsprogress.id === 333);
  }

  get showServiceLocation(): boolean {
    // Default: No
    return (typeof this.showservicelocation !== 'undefined' && typeof this.showservicelocation.id !== 'undefined' && this.showservicelocation.id === 333);
  }

  get showDuration(): boolean {
    // Default: Yes
    return (typeof this.showduration === 'undefined')
      || (typeof this.showduration !== 'undefined' && typeof this.showduration.id !== 'undefined' && this.showduration.id === 333);
  }

  get requiresTimeSlotPicker(): boolean {
    // Default: No
    return (typeof this.requirestimeslotpicker !== 'undefined' && typeof this.requirestimeslotpicker.id !== 'undefined' && this.requirestimeslotpicker.id === 333);
  }

  get showStartTime(): boolean {
    // Default: Yes
    return (typeof this.showstarttime === 'undefined')
      || (typeof this.showstarttime !== 'undefined' && typeof this.showstarttime.id !== 'undefined' && this.showstarttime.id === 333);
  }

  get showInCalendar(): boolean {
    // Default: Yes
    return (typeof this.showincalendar === 'undefined')
      || (typeof this.showincalendar !== 'undefined' && typeof this.showincalendar.id !== 'undefined' && this.showincalendar.id === 333);
  }

  get showEmailContentsField(): boolean {
    // Default: No
    return (typeof this.showemailcontentsfield !== 'undefined' && typeof this.showemailcontentsfield.id !== 'undefined' && this.showemailcontentsfield.id === 333);
  }

  get showTimeSlotType(): boolean {
    // Default: No
    return (typeof this.showtimeslottype !== 'undefined' && typeof this.showtimeslottype.id !== 'undefined' && this.showtimeslottype.id === 333);
  }
}
