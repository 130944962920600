import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService, MessageService, RippleApiService } from '@ripple/services';

@Injectable({
  providedIn: 'root',
})
export class KPIsReportService extends RippleApiService {
  private restURL = '/api/ccasa/kpis';

  constructor(http: HttpClient, authService: AuthService, messageService: MessageService) {
    super(http, messageService, authService);
  }

  public getReport(currentRange: string[], previousRange: string[], clientTypes: number[] = null, counsellors: number[] = null, triageLevels: number[] = null): Promise<any> {

    return this._post(this.restURL, {currentRange, previousRange, clientTypes, counsellors, triageLevels}).toPromise();
  }
}
