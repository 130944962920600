import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../entity-types';


export class CCMReportSubCategory extends WarpEntity {
  // entityTypeId: EntityTypes.CCMReportSubCategory;
  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.CCMReportSubCategory;
  }

  static empty(): CCMReportSubCategory {
    return new CCMReportSubCategory(super.emptyEntity(EntityTypes.CCMReportSubCategory));
  }


}
