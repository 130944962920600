import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged  } from 'rxjs/operators';

export enum SCREEN_SIZE {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL'
}

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {

  private resizeSubject: BehaviorSubject<SCREEN_SIZE>;

  get onResize$(): BehaviorSubject<SCREEN_SIZE> {
    return this.resizeSubject;
  }

  get currentScreenSize(): SCREEN_SIZE {
    return this.resizeSubject.value;
  }

  constructor() {
    this.resizeSubject = new BehaviorSubject(SCREEN_SIZE.XS);
  }

  onResize(size: SCREEN_SIZE) {
    this.resizeSubject.next(size);
  }
}