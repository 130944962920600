import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../entity-types';
import { util } from '@ripple/core';

export class TimeSlotDayTemplate extends WarpEntity {

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.TimeSlotDayTemplate;
  }

  static empty(): TimeSlotDayTemplate {
    const retVal = new TimeSlotDayTemplate(super.emptyEntity(EntityTypes.TimeSlotDayTemplate));
    return retVal;
  }

  static random(): TimeSlotDayTemplate {
    const e = TimeSlotDayTemplate.empty();
    e.properties['name'] = `${util.names({ seed: new Date() })}`;
    e.properties['color'] = util.color('name');
    return e;
  }
}
