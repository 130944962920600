import { environment } from '@ripple/environment';
import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../entity-types';
import { util } from '@ripple/core';
import { Event, weekdays } from './_event';
import { RRule } from 'rrule';

import * as moment_ from 'moment';
const moment = moment_;

export class GenericCalendarEvent extends WarpEntity implements Event {

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.GenericCalendarEvent;
    this.properties.starttime = moment(this.properties.starttime, 'hh:mm A').format('HH:mm');
    this.initEvent();
  }

  initEvent() {
    this.title = this.properties.name;

    // add isRecurring to properties
    this.properties.isRecurring = this.isReccuring() ? 'yes' : 'no';

    let freqString = '';
    let dtstartString = '';
    let untilString = '';
    let byweekdayString = '';
    let exdateString = '';

    // rrule
    if (this.isReccuring()) {
      // repeat
      freqString = this.properties.eventfrequency[0].optionName.toUpperCase();
      dtstartString = moment(`${this.properties.date} ${this.properties.starttime}`).format('YYYYMMDDTHHmmSS') + 'Z';
      untilString = this.properties.enddate ?
                    moment(`${this.properties.enddate}`).add(1, 'd').subtract(1, 's').format('YYYYMMDDTHHmmSS') + 'Z' : '';

      if (this.properties.eventfrequency[0].optionName.toLowerCase() === 'weekly') {
        // only need dayofweek when it repeats weekly
        byweekdayString = this.properties.dayofweek.map(day => RRule[weekdays[day.id - 772].toUpperCase()]).join(',');
      }

      if (this.properties.exceptiondate) {
        const exdateStringArr = [];
        for(const date of this.properties.exceptiondate.split(','))
          exdateStringArr.push(moment(`${date} ${this.properties.starttime}`).format('YYYYMMDDTHHmmSS') + 'Z');
        exdateString = '\nEXDATE:' + exdateStringArr.join(',');
      }

    } else {
      // no repeat
      const startDate = moment(`${this.properties.date} ${this.properties.starttime}`);
      const endDate = moment(`${this.properties.date}`).add(1, 'd').subtract(1, 's');

      freqString = 'DAILY';
      dtstartString = startDate.isValid() ? startDate.format('YYYYMMDDTHHmmSS') + 'Z' : '';
      untilString = endDate.isValid() ? endDate.format('YYYYMMDDTHHmmSS') + 'Z' : '';
    }

    this.rrule = '';
    if (dtstartString)
      // tslint:disable-next-line: prefer-template
      this.rrule += 'DTSTART:' + dtstartString + '\n';

    // tslint:disable-next-line: prefer-template
    this.rrule += 'RRULE:FREQ=' + freqString;

    if (untilString)
      // tslint:disable-next-line: prefer-template
      this.rrule += ';UNTIL=' + untilString;

    if (byweekdayString)
      // tslint:disable-next-line: prefer-template
      this.rrule += ';BYDAY=' + byweekdayString;

    this.rrule += exdateString;

    this.duration = moment.utc().startOf('day').add(this.properties.length, 'minutes').format('HH:mm');

    // resources
    this.resourceIds = [];
    if (this.properties.workers)
      this.properties.workers.forEach(worker => {
        this.resourceIds.push(worker.id);
      });
    if (this.properties.room)
      this.properties.room.forEach(r => {
        this.resourceIds.push(r.id);
      });
    
    const eventTypeStyling = environment.eventTypeStyling[this.entityTypeId];
    this.color = this.entityId < 0 ? eventTypeStyling.newAddedColor : eventTypeStyling.bgColor;
    this.textColor = eventTypeStyling.textColor;
  }

  isReccuring() {
    return this.properties.eventfrequency && this.properties.eventfrequency.length > 0 &&
      this.properties.eventfrequency[0].optionName.toLowerCase() !== 'does not repeat';
  }

  addExceptionDate(dateString: string) {
    if (dateString) {
      if (this.properties.exceptiondate && this.properties.exceptiondate.length > 0)
          this.properties.exceptiondate += ',';
        else
          this.properties.exceptiondate = '';

      this.properties.exceptiondate += dateString;
    }
  }

  updateRecurringForThisOnly(): GenericCalendarEvent {
    const newEvent = GenericCalendarEvent.empty();
    newEvent.properties = { ...this.properties };
    newEvent.property('date', this.today)
            .property('enddate', '')
            .cfcProperty('eventfrequency', 1392); // Does not repeat
    newEvent.properties.dayofweek = null;
    this.reset();
    this.properties.starttime = moment(this.properties.starttime, 'hh:mm A').format('HH:mm');
    this.addExceptionDate(this.today);
    return newEvent;
  }

  updateRecurringForThisAndFollowing(): GenericCalendarEvent {
    const newEvent = GenericCalendarEvent.empty();
    newEvent.properties = { ...this.properties };
    newEvent.property('date', this.today);
    this.reset();
    this.properties.starttime = moment(this.properties.starttime, 'hh:mm A').format('HH:mm');
    this.property('enddate', moment(this.today).subtract(1, 'd').format('YYYY-MM-DD'));
    return newEvent;
  }

  static empty(): GenericCalendarEvent {
    return new GenericCalendarEvent(super.emptyEntity(EntityTypes.GenericCalendarEvent));
  }

  static random(): GenericCalendarEvent {
    const e = GenericCalendarEvent.empty();
    e.properties['name'] = `${util.names({ seed: new Date() })}`;
    e.properties['color'] = util.color('name');
    return e;
  }
}
