// Angular
import '@angular/compiler';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule, APP_INITIALIZER } from '@angular/core';


import { CookieModule } from 'ngx-cookie';
import { HubService } from 'ngx-signalr-hubservice';

// Ripple
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RippleInterceptor, SignalrConnectionCheckerService } from '@ripple/services';
import { WarpEntityCacheFactoryLoaderService, FancyRoutingService } from './_core/services';
import { PartialsModule } from '@ripple/ui';

import { InplaceModule } from 'primeng/inplace';
import { CalendarModule } from 'primeng/calendar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { ConfirmationService } from 'primeng/api';
import { FileSaverModule } from 'ngx-filesaver';

import { HomePageComponent } from './common/home-page/home-page.component';
import { ScreenSizeDetectorComponent } from './common/screen-size-detector/screen-size-detector.component';

import { MsalModule } from '@azure/msal-angular';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    ScreenSizeDetectorComponent,

  ],
  imports: [
    // Angular
    FormsModule,
    CommonModule,
    LayoutModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,

    FileSaverModule,

    AppRoutingModule,
    PartialsModule,

    InplaceModule,
    CalendarModule,
    ProgressSpinnerModule,

    ReactiveFormsModule,
    FormlyModule.forRoot({extras: { checkExpressionOn: 'modelChange' }}),
    FormlyPrimeNGModule,

    CookieModule.forRoot(),
    MsalModule.forRoot({
      auth: null
    }),
    IonicModule.forRoot(),

  ],
  providers: [
    FancyRoutingService,
    ConfirmationService,
    HubService,
    SignalrConnectionCheckerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RippleInterceptor,
      multi: true
    },
    WarpEntityCacheFactoryLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: WarpEntityCacheFactoryLoaderService) => () => {return ds.load()},
      deps: [WarpEntityCacheFactoryLoaderService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
