
import { routes } from '../app/_core';

const host = 'https://ccmdemo.ripplegroup.ca/dashboard';
const rest = 'https://ccmdemo.ripplegroup.ca';

export const environment = {
    subscriberId: 178,
    enableHelpline: false,
    helplineUrl: '',
    production: true,
    eventFolder: 242998,
    eventTemplateFolder: 242999,
    clientFileTemplates: {
        COUNSELLING: { id: 178098, optionName: 'Counselling' },
        CRISIS: { id: 189538, optionName: 'Crisis' },
        GENERAL: { id: 187430, optionName: 'General' },
        GROUP: { id: 202151, optionName: 'Group' },
        POLICE_AND_COURT_SUPPORT: { id: 243038, optionName: 'Police and Court Support' },
        SYSTEM_NAVIGATION: { id: 243039, optionName: 'System Navigation' },
        // PACES: null,
        // CSART: null
    },
    sessionTypesThatNeedTimeSlotPicker: [
        // 1256, // COUNSELLING_SESSION
        // 1257, // PHONE_CALL
        // 1258, // IN_PERSON_SESSION
        // 1259, // COLLATERAL_CONTACT
        // 1260, // ACCOMPANIMENT
        // 1395, // INTAKE
        // 1489, // DISCHARGE
        // 1506, // GROUP_SESSION
        // 1958, // EMAIL
        // 1988, // EVENT
        // 2045, // MICROSITE_SESSION =
        // 2214, // REPORTABLE_DISCLOSURE
        // 2215, // CONSENT
        // 2216, // PACES
        // 2217, // ACCOMPANIMENT_TO_POLICE
        // 2218, // ACCOMPANIMENT_TO_CROWN
        // 2219, // ACCOMPANIMENT_TO_COURT
        // 2220, // CSART_ACCOMPANIMENT
        // 2221, // FOLLOWUP
        // 2222, // RESOURCING_ACCOMPANIMENT
        // 2223, // CRISIS_WALK_IN
        // 2224, // WEEKLY_SESSION
        ],
        externalSites: [
            {
              label: 'CRISIS',
              routerLinkActiveOptions: { alwaysShown: true },
              routerLink: 'clinical/forms/crisis',
            },
            {
              label: 'Education Disclosures',
              routerLinkActiveOptions: { alwaysShown: true },
              routerLink: 'clinical/forms/education-disclosures',
            }
        ],
    logoPath: 'assets/img/logos/demo-nav-logo.png',
    logoPrintPath: 'assets/img/logos/demo-nav-logo-print.png',
    logoLoginPath: 'assets/img/logos/demo-nav-logo-inline.png',
    dashboardUrl: 'dashboard',
    brandName: 'Demo',
    serviceDeskPortal: 'https://ripplegroupcanada.atlassian.net/servicedesk/customer/portal/1',
    host,
    loginPage: 'login',
    restEndpointUrl: rest,
    routes,
    hubUrl: rest + '/signalr',
    caching: {
      defaultLoadStyle: 'lazy',
      defaultPageSize: 1000,
    },
    forgotPasswordUrl: rest + '/recoverypass.aspx',
  noteTypeStyling: {
    'Default':  { highlightColor: 'var(--gray300)', bgColor: 'var(--white)', iconClass: 'pi pi-tag' },
    'Red Flag': { highlightColor: 'var(--orange)', bgColor: 'var(--gray100)', iconClass: 'pi pi-bookmark', flag: true },
    'Client Note': { highlightColor: 'var(--gray500)', bgColor: 'var(--gray100)', iconClass: 'pi pi-user-edit', flag: false },
    'Session Scheduled': { highlightColor: 'var(--gray300)', bgColor: 'var(--white)', iconClass: 'pi pi-clock', flag: false },
    'Added to Waitlist': { highlightColor: 'var(--gray300)', bgColor: 'var(--white)', iconClass: 'pi pi-spinner', flag: false },
    'Phoned Client': { highlightColor: 'var(--gray300)', bgColor: 'var(--white)', iconClass: 'pi pi-mobile', flag: false },
    'File Status Changed': { highlightColor: 'var(--gray300)', bgColor: 'var(--white)', iconClass: 'pi pi-', flag: false },
    'Client Arrived': { highlightColor: 'var(--primary-light)', bgColor: 'var(--gray100)', iconClass: 'pi pi-sign-in', flag: false },
    'Session Complete': { highlightColor: 'var(--gray300', bgColor: 'var(--white)', iconClass: 'pi pi-check-circle', flag: false },
    'Event Note':  { highlightColor: 'var(--gray300)', bgColor: 'var(--white)', iconClass: 'pi pi-tag' },
  },
  eventTypeStyling: {
    628: {
      statusColors: {
        1302: 'var(--gray500)', // waiting list
        1303: 'var(--green)', // scheduled
        1304: 'var(--blue)', // presented
        1305: 'var(--orange)', // complete
        1306: 'var(--red)', // cancelled
      }
    }, /* Education Event */
    633: {
      bgColor: 'var(--primary)',
      textColor: 'var(--primary-text)',
      statusColors: {
        1261: 'var(--gray500)', // pending
        1262: 'var(--green)', // arrived
        1263: 'var(--red)', // no show
        1264: 'var(--orange)', // cancelled by couselor
        1265: 'var(--orange)', // cancelled by client
        1266: 'var(--blue)', // answered
        1267: 'var(--red)', // no answered
        1957: 'var(--orange)' // cancelled by client (late)
      }
    }, /* Session */
    659: {
      bgColor: 'var(--gray300)',
      textColor: 'var(--gray900)',
      typeColors : {
        2701: 'var(--pink)', // new
        1968: 'var(--red)', // P1
        1969: 'var(--orange)', // P2
        1970: 'var(--yellow)', // P3
        1971: 'var(--green)', // P4
        1972: 'var(--primary-light)', // P5
        1973: 'var(--primary-dark)', // rebook
        2702: 'var(--gray)', // Unspecified
      }
    }, /* Timeslot */
    660: { bgColor: 'var(--gray300)', textColor: 'var(--gray900)' }, /* TimeslotDayTemplate */
    661: { bgColor: 'var(--secondary)', textColor: 'var(--secondary-text)' }, /* GenericCalendarEvent */
  },
  logExemptComponents: [
    'HomePageComponent',
  ],
  tokenName: 'token',
};
