import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../../entity-types';

export class ClientFileTemplateForm extends WarpEntity {
  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.ClientFileTemplateForm;
  }

  static empty(): ClientFileTemplateForm {
    return new ClientFileTemplateForm(super.emptyEntity(EntityTypes.ClientFileTemplateForm));
  }
}
