import { environment } from '@ripple/environment';
import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../entity-types';
import { util } from '@ripple/core';
import { Event } from './_event';

import * as moment_ from 'moment';
import { ClientFile } from '../extended/client-file/client-file'; //prevent circular dep
const moment = moment_;

// We will still Keep EVENT and MICROSITE_SESSION
export enum SessionTypes {
  COUNSELLING_SESSION = 1256,
  PHONE_CALL = 1257,
  IN_PERSON_SESSION = 1258,
  COLLATERAL_CONTACT = 1259,
  ACCOMPANIMENT = 1260,
  INTAKE = 1395,
  DISCHARGE = 1489,
  GROUP_SESSION = 1506,
  EMAIL = 1958,
  EVENT = 1988,
  MICROSITE_SESSION = 2045,
  REPORTABLE_DISCLOSURE = 2214,
  CONSENT = 2215,
  PACES = 2216,
  ACCOMPANIMENT_TO_POLICE = 2217,
  ACCOMPANIMENT_TO_CROWN = 2218,
  ACCOMPANIMENT_TO_COURT =  2219,
  CSART_ACCOMPANIMENT = 2220,
  FOLLOWUP = 2221,
  RESOURCING_ACCOMPANIMENT = 2222,
  CRISIS_WALK_IN = 2223,
  WEEKLY_SESSION = 2224,
}

export enum SessionStatus {
  PENDING = 1261,
  ARRIVED = 1262,
  NO_SHOW = 1263,
  CANCELLED_BY_COUNSELOR = 1264,
  CANCELLED_BY_CLIENT = 1265,
  ANSWERED = 1266,
  NO_ANSWER = 1267,
  CANCELLED_BY_CLIENT_LATE = 1957,
  COMPLETED = 2759,
  LEFT_MESSAGED = 2760,
  UNABLE_TO_LEAVE_MESSAGE = 2761,
}

export class Session extends WarpEntity implements Event {
  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.Session;
    this.title = this.generateEventTitle();
    this.start = this.properties.date;
    this.end = this.properties.date;
    if (this.properties.starttime)
      this.start = moment(`${this.properties.date} ${this.properties.starttime}`).format('YYYY-MM-DDTHH:mm:SS');
    if (this.properties.endtime)
      this.end = moment(`${this.properties.date} ${this.properties.endtime}`).format('YYYY-MM-DDTHH:mm:SS');
    this.resourceIds = [];
    if (this.properties.workers && this.properties.workers[0])
      this.resourceIds.push(this.properties.workers[0].id);

    this.properties.starttime = moment(this.properties.starttime, 'hh:mm A').format('HH:mm');
    this.properties.endtime = moment(this.properties.endtime, 'hh:mm A').format('HH:mm');

    const eventTypeStyling = environment.eventTypeStyling[this.entityTypeId];
    this.color = this.entityId < 0 ? eventTypeStyling.newAddedColor : eventTypeStyling.bgColor;
    this.textColor = eventTypeStyling.textColor;

    if (this.clientFile)
      this.clientFile = new ClientFile(this.clientFile);

    this.modify_preSave(true, Session.saveClientFileNextSession);
  }

  get dateTime(): string {
    return `${this.properties.date} ${this.properties.starttime}`;
  }

  get duration(): any {
    const duration = moment.duration(moment(`${this.properties.date} ${this.properties.endtime}`).diff(moment(`${this.properties.date} ${this.properties.starttime}`))).asHours();
    if (!duration)
        return 0;
    if (duration < 0)
        return moment.duration(moment(`${this.properties.date} ${this.properties.endtime}`).add(1, 'day').diff(moment(`${this.properties.date} ${this.properties.starttime}`))).asHours();
    return duration;
  }

  get endDateTime(): string {
    return `${this.properties.date} ${this.properties.endtime}`;
  }

  get clientsStr(): string {
    return this.properties.clients instanceof Array ? this.properties.clients.map(c => c.name).join(',') : '';
  }

  isCancelled() {
    const statusId = this.sessionstatus ? this.sessionstatus.id : -1;
    return statusId !== SessionStatus.PENDING && statusId !== SessionStatus.ARRIVED && statusId !== SessionStatus.ANSWERED  && statusId !== SessionStatus.COMPLETED  && statusId !== SessionStatus.LEFT_MESSAGED && statusId !== SessionStatus.UNABLE_TO_LEAVE_MESSAGE;
  }

  generateEventTitle() {
    let retVal = '';
    if (this.properties.clients && this.properties.clients instanceof Array) {
      for (const client of this.properties.clients) {
        if (retVal) retVal += ',';
        const nameArr = client.name.split(' ');
        retVal += (`${nameArr[0]} ${nameArr[1]?.charAt(0)}.`);
      }
    }
    if (this.name)
      retVal += ` - ${this.name.indexOf(' on ') > -1 ? this.name.substring(0, this.name.indexOf(' on ')) : this.name}`;
    else
      retVal += ` - New Session`;
    return retVal;

  }

  static saveClientFileNextSession(w: WarpEntity): boolean {
    return true;
  }

  static empty(): Session {
    return new Session(super.emptyEntity(EntityTypes.Session));
  }

  static random(): Session {
    const e = Session.empty();
    e.properties['name'] = `${util.names({ seed: new Date() })}`;
    e.properties['color'] = util.color('name');
    return e;
  }

}
