import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@ripple/environment';
import { AuthService, WarpEntityLogService } from '@ripple/services';
import { LayoutSettingsService } from '@ripple/services';
import { BasePage } from '@ripple/core';

import { environment as ccmEnvironment } from '../../../environments/environment';

@Component({
  selector: 'ccasa-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BasePage implements OnInit, OnDestroy {
  get componentName(): string {
    return 'LoginComponent';
  }
  homePage = '/';

  userName = '';
  password = '';
  mfaCode: string | number = '';
  partialToken = '';
  mfaToken = '';
  logoPath = ccmEnvironment.logoLoginPath;
  statusText = '';

  enterMFACode = false;
  loggingIn = false;
  forgotpasswordurl = '';
  brandName = ccmEnvironment.brandName;

  returnUrl = '';

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private authService: AuthService,
    private layoutSettingsService: LayoutSettingsService,
    private logService: WarpEntityLogService
  ) {
    super(logService);
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.ReturnUrl;
    this.loggingIn = false;
    this.forgotpasswordurl = environment.forgotPasswordUrl;

    const layoutSettings = this.layoutSettingsService.getSettings();
    layoutSettings.showMenuBar = false;
    this.layoutSettingsService.setSettings(layoutSettings);
  }

  ngOnDestroy(): void {
    this.layoutSettingsService.revertSettings();
    this.clearSubs();
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string): string {
    return '';
  }

  submitForm(): void {
    this.loggingIn = true;
    this.authService
      .validateLogin({
        userName: this.userName,
        password: this.password,
        mfaCode: this.mfaCode.toString(),
        partialToken: this.partialToken,
        mfaToken: this.mfaToken,
      })
      .then((response) => {
        this.loggingIn = false;
        this.mfaCode = '';
        this.statusText = response.statusText ?? '';
        this.partialToken = response.partialToken ?? '';
        let baseHref = '';
        if (!window.location.href.includes('localhost')) {
          baseHref = '/' + ccmEnvironment.dashboardUrl;
        }
        if (response.token) {
          if (
            response.user &&
            response.user.roles &&
            response.user.roles.length == 1 &&
            response.user.roles[0].name == 'PACES'
          ) {
            // this.router.navigate(['/paces']);
            window.location.href = baseHref + "/paces";
          } else if (
            response.user &&
            response.user.roles &&
            response.user.roles.length == 1 &&
            response.user.roles[0].name == 'CSART Staff'
          ) {
            window.location.href = baseHref + '/csart';
            // this.router.navigate(['/csart']);
          } 
          else {
            // this.router.navigate([this.returnUrl || this.homePage]).catch((_) => this.router.navigate([this.homePage]));
            try {
              window.location.href = baseHref + (this.returnUrl || this.homePage);
            } catch (error) {
              window.location.href = baseHref + this.homePage;
            }
          }
        } else if (response.partialToken) {
          this.partialToken = response.partialToken;
          this.statusText = response.statusMessage;
          this.enterMFACode = true;
        } else {
          this.loggingIn = false;
        }
      })
      .catch((error) => {
        this.loggingIn = false;
        this.statusText = error.error;

        if (error.error instanceof ProgressEvent) {
          this.statusText = 'Login failed, please try again.';
        }

        if (!this.statusText) {
          const status = error.status;
          const messageArr = error.message.split(status);
          if (messageArr.length > 1)
            this.statusText = messageArr[1];
          else
            this.statusText = error.message;
        }
      });
  }

  cancelSubmittingMFA(): void {
    this.enterMFACode = false;
  }

  isEmptyField(): boolean {
    if (!this.enterMFACode) return !this.userName || !this.password;
    else return !this.mfaCode.toString();
  }
}
