
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService, MessageService, RippleApiService } from '@ripple/services';
import { Observable, Subscribable } from 'rxjs';
import { CCMAggregateReportStat, CCMReportSubCategory } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class AggregateReportService extends RippleApiService {
  private restURL = '/api/ccasa/precannedreport';

  constructor(http: HttpClient, authService: AuthService, messageService: MessageService) {
    super(http, messageService, authService);
  }

  public addStatToReport(statInfo: any, reportId: number, json: any, statType: string, statName: string, isCategory: boolean = false, orderIndex: number = null): Promise<any> {
    const body = {
      statInfo,
      json,
      reportId,
      statType,
      statName,
      isCategory,
      orderIndex
    }

    return this._post(this.restURL + '/addstattoreport', body).toPromise();
  }

  public getReportStats(reportId): Promise<any>  {
    return this._get(this.restURL + '/getreportstats/' + reportId.toString()).toPromise();
  }

  public getReport(reportId): Observable<{cat: CCMReportSubCategory, stats: CCMAggregateReportStat[], subcats: {cat: CCMReportSubCategory, stats: CCMAggregateReportStat}[]}[]> {
    return this._get(this.restURL + '/getreport/' + reportId.toString());
  }

  public generateReport(reportId, startDate, endDate, monthly, quarterly): Promise<any> {
    return this._get(this.restURL + '/generatreport/' + reportId.toString() + '/' + startDate + '/' + endDate + '/' + monthly + '/' + quarterly ).toPromise();
  }
}

