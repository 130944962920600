import { WarpEntity } from '@ripple/models';
import { Person } from './_person';
import { Resource } from './_resource';

import { environment } from '@ripple/environment';

export class User extends WarpEntity implements Person, Resource {
    id: string | number;
    //img = 'assets/img/anonymousUser.png';
    firstname: string;
    lastname: string;

    constructor(obj) { // direct conversion from
        super(obj);
        this.title = this.label;
    }

    get displayName(): string {
        return `${this.firstname} ${this.lastname}`;
    }

    get img(): string {
        return this.userImg ? `${environment.restEndpointUrl}/api/blob/` + this.userImg.guid : 'assets/img/anonymousUser.png';
    }

    get initials(): string {
        return (this.firstname ? this.firstname.charAt(0) : '')
                + (this.lastname ? this.lastname.charAt(0) : '');
    }
}
