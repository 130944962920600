<div class="login-body">
  <div class="login-panel"></div>
  <div class="login-content">
    <img alt="logo" src="{{ logoPath }}">

    <form name="loginForm" method="post">
      <ripple-loading-box [loading]="loggingIn" [fullHeight]="true">
        <h1><span>SIGN IN TO</span> {{brandName}} </h1>

        <ng-container *ngIf="!enterMFACode">
          <p>Welcome! Please use the form to sign-in.</p>

          <div class="p-field">
            <div class="login-input-wrapper">
              <input id="usernameField" type="text" name="userName" pInputText placeholder="Username" [(ngModel)]="userName" (keyup.Enter)="submitForm()" />
              <i class="pi pi-user"></i>
            </div>
          </div>
          <div class="p-field">
            <div class="login-input-wrapper">
              <input id="passwordField" type="password" name="password" pPassword [feedback]="false" placeholder="Password" [(ngModel)]="password" (keyup.Enter)="submitForm()" />
              <i class="pi pi-lock"></i>
            </div>
            <div class="help-text"><a [href]="forgotpasswordurl" target="_blank">Forgot your password?</a></div>
          </div>
          <div class="p-field">
            <div class="help-text">
              <span class="error-text">{{statusText}}</span>
            </div>
          </div>
          <div class="p-field">
            <button id="loginButton" pButton type="button" label="Login" [disabled]="isEmptyField()" (click)="submitForm()"></button>
          </div>
        </ng-container>

        <ng-container *ngIf="enterMFACode">
          <p>Please enter your login confirmation code.</p>
          <div class="p-field">
            <div class="login-input-wrapper">
              <input id="mfaCodeField" type="number" name="mfaCode" pInputText placeholder="MFA Code 6 digits" [(ngModel)]="mfaCode" />
              <i class="pi pi-lock"></i>
            </div>
            <div class="help-text"><span class="error-text" [hidden]="mfaCode && mfaCode.toString().length > 0">{{statusText}}</span></div>
          </div>
          <div class="p-field">
            <button pButton type="button" label="Submit" [disabled]="isEmptyField()" (click)="submitForm()"></button>
            <button pButton type="button" label="Cancel" (click)="cancelSubmittingMFA()"></button>
          </div>
        </ng-container>
      </ripple-loading-box>
    </form>
  </div>
</div>

<div class="login-panel-container" [hidden]="true">
  <div class="login-panel">
    <div class="login-form">
      <div style="margin-bottom: 20px;">
        <img src="assets/img/logos/ccasa-nav-logo-inline.png">
      </div>
      <ripple-loading-box [loading]="loggingIn" [fullHeight]="true">
        <form name="loginForm" method="post">
          <p-panel header="Login to your account" [hidden]="enterMFACode">

            <div class="ui-fluid">
              <div class="p-field">
                <div class="ui-inputgroup">
                  <span class="ui-inputgroup-addon"><i class="pi pi-user"></i></span>
                  <input id="usernameField" type="text" name="userName" pInputText placeholder="Username" [(ngModel)]="userName" />
                </div>
              </div>
              <div class="p-field">
                <div class="ui-inputgroup">
                  <span class="ui-inputgroup-addon"><i class="pi pi-lock"></i></span>
                  <input id="passwordField" type="password" name="password" pPassword [feedback]="false" placeholder="Password" [(ngModel)]="password" />
                </div>
              </div>
              <span class="error-text">{{statusText}}</span>
              <button pButton type="button" label="Login" [disabled]="isEmptyField()" (click)="submitForm()"></button>
              <a [href]="forgotpasswordurl" target="_blank">Forgot your password?</a>
            </div>

          </p-panel>

          <p-panel header="Enter your MFA Code" [hidden]="!enterMFACode">

            <div class="ui-fluid">
              <div class="p-field">
                <div class="ui-inputgroup">
                  <span class="ui-inputgroup-addon"><i class="pi pi-user"></i></span>
                  <input id="mfaCodeField" type="number" name="mfaCode" pInputText placeholder="MFA Code 6 digits"
                    [(ngModel)]="mfaCode" />
                </div>
              </div>
              <span class="error-text" [hidden]="mfaCode && mfaCode.toString().length > 0">{{statusText}}</span>
              <button pButton type="button" label="Submit" [disabled]="isEmptyField()" (click)="submitForm()"></button>
              <button pButton type="button" label="Cancel" (click)="cancelSubmittingMFA()"></button>
            </div>

          </p-panel>
        </form>
      </ripple-loading-box>
    </div>
  </div>
</div>
