import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../../entity-types';
import { Resource } from '../_resource';

export class EventRequest extends WarpEntity {

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.EventRequest;
    }

    static empty(): EventRequest {
        return new EventRequest(super.emptyEntity(EntityTypes.EventRequest));
    }

}
