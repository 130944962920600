import { Injectable, Injector } from '@angular/core';
import {
  User,
  Client,
  ClientFile,
  Contact,
  EducationEvent,
  EntityTypes,
  EventFolder,
  EventTemplate,
  FilledForm,
  Session,
  EventRequest,
  EventType,
  WaitingList,
  ClientFileTemplateSession,
  ClientFileTemplateForm,
  Room,
  EventInvitation,
  GenericCalendarEvent,
  ServiceLocation,
  TimeSlot,
  TimeSlotDayTemplate,
  ClientFileTemplate,
  CCMAggregateReport,
  CCMAggregateReportStat,
  CCMReportSubCategory
} from '../../../_core/models';

import { WarpEntityCacheFactoryService  } from '@ripple/services';

import { ClientServiceCache } from './client.service';
import { SessionServiceCache } from './session.service';
import { ClientFileServiceCache } from './client-file.service';
import { EventFolderServiceCache } from './eventFolder.service';
import { ClientFileTemplateSessionCache } from './client-file-template-session.service';
import { WarpEntity } from '@ripple/models';

@Injectable({
  providedIn: 'root'
})
export class WarpEntityCacheFactoryLoaderService  {

  constructor(private serviceFactory: WarpEntityCacheFactoryService) {
    }

    load(): Promise<any> {
      this.loadCaches();
      this.loadModels();
      return Promise.resolve();
    }

    loadCaches() {
      // you must add every extended cache here
      this.serviceFactory.registerExtended(EntityTypes.EventFolder, EventFolderServiceCache);
      this.serviceFactory.registerExtended(EntityTypes.Session, SessionServiceCache);
      this.serviceFactory.registerExtended(EntityTypes.Client, ClientServiceCache);
      this.serviceFactory.registerExtended(EntityTypes.ClientFile, ClientFileServiceCache);
      this.serviceFactory.registerExtended(EntityTypes.ClientFileTemplateSession, ClientFileTemplateSessionCache);
    }

    getModelType(entityTypeId: number) {
      // you must add every model defined in EntityTypes here
      switch (entityTypeId) {
        case 2:
        case EntityTypes.User:
          return User;
        case EntityTypes.Client:
          return Client;
        case EntityTypes.Session:
          return Session;
        case EntityTypes.ServiceLocation:
          return ServiceLocation;
        case EntityTypes.TimeSlot:
          return TimeSlot;
        case EntityTypes.TimeSlotDayTemplate:
          return TimeSlotDayTemplate;
        case EntityTypes.GenericCalendarEvent:
          return GenericCalendarEvent;
        case EntityTypes.EventInvitation:
          return EventInvitation;
        case EntityTypes.FilledForm:
          return FilledForm;
        case EntityTypes.Room:
          return Room;
        case EntityTypes.ClientFile:
          return ClientFile;
        case EntityTypes.ClientFileTemplate:
          return ClientFileTemplate;
        case EntityTypes.ClientFileTemplateForm:
          return ClientFileTemplateForm;
        case EntityTypes.ClientFileTemplateSession:
          return ClientFileTemplateSession;
        case EntityTypes.WaitingList:
          return WaitingList;
        case EntityTypes.EducationEvent:
          return EducationEvent;
        case EntityTypes.EventType:
          return EventType;
        case EntityTypes.EventRequest:
          return EventRequest;
        case EntityTypes.EventFolder:
          return EventFolder;
        case EntityTypes.EventTemplate:
          return EventTemplate;
        case EntityTypes.Contact:
          return Contact;
        case EntityTypes.CCMAggregateReport:
          return CCMAggregateReport;
        case EntityTypes.CCMAggregateReportStat:
          return CCMAggregateReportStat;
        case EntityTypes.CCMReportSubCategory:
          return CCMReportSubCategory;


        default:
          throw new Error(`No mapping for entityTypeId: ${entityTypeId}, you need to add mapping to ${EntityTypes[entityTypeId]} in getModelType()`);
      }
    }

    loadModels() {
      Object.keys(EntityTypes).forEach(k => {
        let entityTypeId = parseInt(k);
        if(entityTypeId > 0)
          this.serviceFactory.registerModel<WarpEntity>(entityTypeId, this.getModelType(entityTypeId));
      });
    }
}
