// subscriber specific names to use for ripple entity types
// THESE ALSO MUST BE ADDED TO warp-entity-cache-factory-LOADER.service.ts

export enum EntityTypes {
  Default = 0,

  User = 583,
  Client = 627,
  Session = 633,
  ServiceLocation = 634,
  TimeSlot = 659,
  TimeSlotDayTemplate = 660,
  GenericCalendarEvent = 661,
  EventInvitation = 664,

  FilledForm = 636,
  Room = 662,

  ClientFile = 643,
  ClientFileTemplate = 644,
  ClientFileTemplateForm = 646,
  ClientFileTemplateSession = 645,
  WaitingList = 677,

  // education
  EducationEvent = 628,
  EventType = 637,
  EventRequest = 665,
  EventFolder = 639,
  EventTemplate = 641,
  Contact = 72,

  CCMAggregateReport = 741,
  CCMAggregateReportStat = 742,
  CCMReportSubCategory = 765,
}
