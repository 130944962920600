import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../../entity-types';
import { Resource } from '../_resource';

export class Room extends WarpEntity implements Resource {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.title = this.label;
        this.entityTypeId = EntityTypes.Room;
    }

    static empty(): Room {
        return new Room(super.emptyEntity(EntityTypes.Room));
    }

}
