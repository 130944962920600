import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService, MessageService, RippleApiService } from '@ripple/services';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService extends RippleApiService {
  private restURL = '/api/ccasa/schedule';

  constructor(http: HttpClient, authService: AuthService, messageService: MessageService) {
    super(http, messageService, authService);
  }

  public getHiddenSessions(startString: string, endString: string): Promise<any[]> {
    return this._post(this.restURL.concat('/hidden-sessions'), { startString, endString }).toPromise();
  }
}
