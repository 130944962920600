import { Component, ComponentFactoryResolver, Injector, OnInit, Type } from '@angular/core';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';

import { BehaviorSubject, Subscription } from 'rxjs';

import { MenuItem } from 'primeng/api';

import {
  MessageService,
  AuthService,
  InternalCookieService,
  CookieType,
  MenuService,
  WarpEntityLogService,
  SignalrConnectionCheckerService,
} from '@ripple/services';
import { ITopLevelComponent } from '@ripple/models';
import { LayoutSetting, LayoutSettingsService } from '@ripple/services';
import { BasePage } from '@ripple/core';
import { environment as ccmEnvironment } from '../environments/environment';
import { FancyRoutingService } from 'subscribers/ccasa-emr/src/app/_core/services/handoff/fancy-routing.service';

@Component({
  selector: 'ccasa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BasePage {
  get componentName(): string {
    return 'AppComponent';
  }
  title = 'ccasa-emr';

  currentView = 'Home';
  menu: MenuItem[] = [];
  sections: MenuItem[] = [
    { routerLink: '/clinical', label: 'Clinical', icon: 'pi pi-id-card' },
    { routerLink: '/education', label: 'Education', icon: 'pi pi-desktop' },
    { routerLink: '/administration', label: 'Admin', icon: 'pi pi-globe' },
  ];
  userMenuItems: MenuItem[] = [
    { routerLink: '/settings/account', label: 'Edit Profile', icon: 'pi pi-user' },
    { routerLink: '/settings/timeslots', label: 'My Time Slots', icon: 'pi pi-cog' },
    { routerLink: '/settings/notification', label: 'Notifications', icon: 'pi pi-bell' },
    { command: () => this.authService.logout(window.location.pathname), label: 'Log Out', icon: 'pi pi-sign-out' },
  ];

  layoutSettingSubject: BehaviorSubject<LayoutSetting>;
  layoutSettings: LayoutSetting;
  logoPath: string = ccmEnvironment.logoPath;
  logoPrintPath: string = ccmEnvironment.logoPrintPath;
  brandName: string = ccmEnvironment.brandName;
  enableHelpline = false;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private authService: AuthService,
    private cookieService: InternalCookieService,
    public layoutSettingsService: LayoutSettingsService,
    private menuService: MenuService,
    private logService: WarpEntityLogService,
    fancyRouting: FancyRoutingService,
  ) {
    super(logService);
    this.sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentSection = this.sections
          .concat([{
            routerLink: 'settings',
            label: 'Settings'
          }])
          .find(section =>
            event.url.substring(1)
              .split('/')
              .find( urlSeg =>
                section.routerLink && section.routerLink.includes(urlSeg)));

        this.currentView = currentSection ? currentSection.label : 'Home';

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page--loaded');
        }, 500);
      }
    });
    this.layoutSettingSubject = this.layoutSettingsService.getSettingsSubject();
    this.layoutSettingSubject.subscribe((layoutSettings) => {
      this.layoutSettings = layoutSettings;
    });

    menuService.setServiceDeskURL(ccmEnvironment.serviceDeskPortal);

    if (this.enableHelpline = fancyRouting.enableHelpline) // test and save enableHelpline
      this.sections.push({ command: () => fancyRouting.route('/helpline/'), label: 'Helpline', icon: 'pi pi-external-link' });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string): string {
    return '';
  }

  public hideAllContent(): boolean {
    return this.authService.hideAllContent;
  }

  // onRouterOutletLoad(componentReference: ITopLevelComponent) {
  //   this.disableHeader = this.router.url && (this.router.url.startsWith('/login') ||
  //                                             this.router.url.startsWith('/print'));
  //   this.disableFooter = this.router.url && (this.router.url.startsWith('/print'));
  //   this.hideNotifications = this.router.url && this.router.url === '/';

  //   if (this.router.url && (this.router.url.startsWith('/kiosk'))) {
  //     this.menuBarDisableNavigating = true;
  //   }
  // }
}
