import { Component } from '@angular/core';
import { AuthService, WarpEntityLogService } from '@ripple/services';
import { BasePage } from '@ripple/core';

// KG: we import directly so that a full import of index.j doesn't cause this to compile after lots of things we don't need
import { FancyRoutingService } from '../../_core/services/handoff/fancy-routing.service';
// import { AppComponent } from 'subscribers/ccasa-emr/src/app/app.component';

@Component({
  selector: 'ccasa-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent extends BasePage {
  get componentName(): string {
    return 'HomePageComponent';
  }

  loggedIn = false;

  constructor(
    authService: AuthService,
    logService: WarpEntityLogService,
    // public appComponent: AppComponent,
    public fancyRouting: FancyRoutingService,
  ) {
    super(logService);
    authService.checkLogin().then(loggedIn => {
      if (this.loggedIn = loggedIn) // check if logged in *and save result*
        this.fancyRouting.tryDefault();
    });
  }

  logFromUrl(url: string): number | number[] { return -1; }

  getLogFormat(url: string): string { return ''; }

  selectSystem(route: string) { this.fancyRouting.route(route); }

}
