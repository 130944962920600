import { Injectable, Injector } from '@angular/core';
import { MessageService, WarpEntityServiceCache } from '@ripple/services';
import { HttpClient } from '@angular/common/http';
import { WarpEntity, EntityFilter } from '@ripple/models';
import { Event, EntityTypes, ClientFileTemplateSession, Session } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ClientFileTemplateSessionCache extends WarpEntityServiceCache<ClientFileTemplateSession> {

  constructor(injector: Injector) {
    super(injector, ClientFileTemplateSession, EntityTypes.ClientFileTemplateSession);
    this.loadFilter = new EntityFilter();
    this.loadFilter.loadChildren(646);
  }

  public updateAvailableSessionTemplates(allSessionTemplates: ClientFileTemplateSession[], sessions: Session[]): ClientFileTemplateSession[] {

    var availableSessionTemplates = [];
    let usedGroupingTagIds = [];
    
    allSessionTemplates.sort((a, b) => (a.itemorderindex > b.itemorderindex ? 1 : -1));
    
    for (const template of allSessionTemplates) {
      if (template.ishidden === '1')
        continue;

      if (template.groupingtag) { // sequenced template
        let used = false;
        for (const session of sessions) { // go through the sessions, if we've used this template already, skip it
          if (session.linkedclientfiletemplatesession && session.linkedclientfiletemplatesession.id === template.id) {
            used = true;
            break;
          }
        }

        if (!used) {
          if (!usedGroupingTagIds.includes(template.groupingtag.id))
            availableSessionTemplates.push(template);

          usedGroupingTagIds.push(template.groupingtag.id);
        }
      }
      else { // regular template
        availableSessionTemplates.push(template);
      }
    }

    return availableSessionTemplates.sort((a, b) => {
      if (a.default == b.default) return a.itemorderindex - b.itemorderindex;
      return b.default ? 1 : -1;
    });
  }

}
