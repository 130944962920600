import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../entity-types';
import { util } from '@ripple/core';
import { Event } from './_event';

import * as moment_ from 'moment';
const moment = moment_;

const contactFields = ['possiblevolunteers', 'confirmedvolunteers', 'attendees'];

export const EventStatus = {
  WAITING_LIST: { id: 1302, optionName: 'Waiting List' },
  SCHEDULED: { id: 1303, optionName: 'Scheduled' },
  PRESENTED: { id: 1304, optionName: 'Presented' },
  COMPLETE: { id: 1305, optionName: 'Complete' },
  CANCELLED: { id: 1306, optionName: 'Cancelled' },
};

export class EducationEvent extends WarpEntity implements Event {
  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.EducationEvent;
    this.title = this.properties.name;
    if (this.properties.eventtemplate && this.properties.eventtemplate.length > 0) {
      this.title += " - ";
      for (const template of this.properties.eventtemplate) {
        this.title += template.name + ', ';
      }
      this.title = this.title.substring(0, this.title.length - 2);
    }
    this.properties.starttime = moment(this.properties.starttime, 'hh:mm A').format('HH:mm');
    this.properties.endtime = moment(this.properties.endtime, 'hh:mm A').format('HH:mm');
    this.start = moment(`${this.properties.date} ${this.properties.starttime}`).format('YYYY-MM-DDTHH:mm:SS');
    this.end = moment(`${this.properties.date} ${this.properties.endtime}`).format('YYYY-MM-DDTHH:mm:SS');
    if (this.start == 'Invalid date') this.start = '';
    if (this.end == 'Invalid date') this.end = '';
    this.modify_preSave(true, EducationEvent.educationEventPreSave);

    // init Contact Fields
    for (const field of contactFields) {
      if (!this.properties[field]) this.properties[field] = [];
    }

    // resources
    this.resourceIds = [];
    const resourceFields = [...contactFields, 'possibleworkers', 'confirmedworkers'];
    for (const field of resourceFields) {
      if (this.properties[field])
        this.properties[field].forEach((contact) => {
          this.resourceIds.push(contact.id);
        });
    }

    // get rgb color
    this.color = this.color ? this.color : '#000000';
    const colorArr = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.color);
    this.rgbColor = colorArr
      ? {
          r: parseInt(colorArr[1], 16),
          g: parseInt(colorArr[2], 16),
          b: parseInt(colorArr[3], 16),
        }
      : null;
    // get text color
    const hsp = Math.sqrt(
      0.299 * (this.rgbColor.r * this.rgbColor.r) +
        0.587 * (this.rgbColor.g * this.rgbColor.g) +
        0.114 * (this.rgbColor.b * this.rgbColor.b)
    );
    if (hsp > 127.5) this.textColor = 'var(--secondary-text)';
    // bg-light
    else this.textColor = 'var(--primary-text)'; // bg-dark

    // init available forms
    if (this.properties.availableforms)
      if (this.properties.availableforms instanceof Array)
        this.properties.availableforms = this.properties.availableforms.map((af) => parseInt(af.value, 10));
      else if (typeof this.properties.availableforms === 'string')
        this.properties.availableforms = [parseInt(this.properties.availableforms, 10)];

    if (this.properties.autocreateforms)
      if (this.properties.autocreateforms instanceof Array)
        this.properties.autocreateforms = this.properties.autocreateforms.map(af => parseInt(af.value, 10));
      else if (typeof this.properties.autocreateforms === 'string')
        this.properties.autocreateforms = [parseInt(this.properties.autocreateforms, 10)];

    if (this.properties.availableformsfacilitator)
      if (this.properties.availableformsfacilitator instanceof Array)
        this.properties.availableformsfacilitator = this.properties.availableformsfacilitator.map(af => parseInt(af.value, 10));
      else if (typeof this.properties.availableformsfacilitator === 'string')
        this.properties.availableformsfacilitator = [parseInt(this.properties.availableformsfacilitator, 10)];

    if (this.properties.autocreateformsfacilitator)
      if (this.properties.autocreateformsfacilitator instanceof Array)
        this.properties.autocreateformsfacilitator = this.properties.autocreateformsfacilitator.map(af => parseInt(af.value, 10));
      else if (typeof this.properties.autocreateformsfacilitator === 'string')
        this.properties.autocreateformsfacilitator = [parseInt(this.properties.autocreateformsfacilitator, 10)];

    // init show attendees (need to have same reference of choices (attendees))
    if (this.properties.showingattendees) {
      const showingAttendeesWithSameRef = [];
      const properties = this.properties; // for some reason this.properties is undefined in the for loop
      for (const attendee of this.properties.showingattendees) {
        const attendeeSameRef = properties.attendees.find((at) => at.id === attendee.id);
        if (attendeeSameRef) showingAttendeesWithSameRef.push(attendeeSameRef);
      }
      this.properties.showingattendees = showingAttendeesWithSameRef;
    }

    // init isoffsite
    this.properties.isoffsite = this.properties.isoffsite && this.properties.isoffsite.toLowerCase() === 'true';

    // init required workers and volunteers
    if (!this.properties['required#workers']) this.properties['required#workers'] = 0;
    if (!this.properties['required#volunteers']) this.properties['required#volunteers'] = 0;

    // check if the # of workers and/or volunteers required is > 0 && < the number of confirmed workers or volunteers
    if (this.properties['required#workers'] > 0 &&
      this.properties.confirmedworkers &&
      this.properties['required#workers'] < this.properties.confirmedworkers.length) {
        this.showRedDot = true;
    }

    if (this.properties['required#volunteers'] > 0 &&
      this.properties.confirmedvolunteers &&
      this.properties['required#volunteers'] < this.properties.confirmedvolunteers.length) {
        this.showRedDot = true;
    }
  }

  static empty(): EducationEvent {
    return new EducationEvent(super.emptyEntity(EntityTypes.EducationEvent));
  }

  static random(): EducationEvent {
    const e = EducationEvent.empty();
    e.properties['name'] = `${util.names({ seed: new Date() })}`;
    return e;
  }

  static educationEventPreSave(w: WarpEntity): boolean {
    for (const field of contactFields) {
      if (w.properties && w.properties[field] && w.properties[field].length === 0) w.properties[field] = null;
    }

    if (w.properties.eventlocationtype?.length > 0 && w.properties.eventlocationtype[0] == undefined)
      w.properties.eventlocationtype = null;

    if (w.properties.isoffsite) w.properties.isoffsite = w.properties.isoffsite.toString();
    return true;
  }

  get isDateConfirmed(): boolean {
    if (this.properties['dateconfirmed'] && this.properties['dateconfirmed'][0].id === 333)
      return true;
    else
      return false;
  }

  get areFacilitatorsConfirmed(): boolean {
    if (this.properties['facilitatorsconfirmed'] && this.properties['facilitatorsconfirmed'][0].id === 333)
      return true;
    else
      return false;
  }
}
