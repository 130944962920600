import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../../entity-types';

export const ClientFileStatus = {
  ON_WAITING_LIST: { optionName: 'On Waiting List', value: 1325 },
  OPEN: { optionName: 'Open', value: 1326 },
  CLOSED: { optionName: 'Closed', value: 1327 },
  INACTIVE: { optionName: 'Inactive', value: 1328 },
  NO_RESPONSE: { optionName: 'No Responose', value: 1329 },
  NOT_SUITABLE_AT_THIS_TIME: { optionName: 'Not Suitable at This Time', value: 1955 },
  NOT_SUITABLE: { optionName: 'Not Suitable', value: 1956 },
};

export class ClientFile extends WarpEntity {
  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.ClientFile;
  }

  isGroupFile() {
    return this.isgroupfile && this.isgroupfile.id === 736;
  }

  isArchived() {
    return this.isarchived && this.isarchived.id === 333;
  }

  static empty(): ClientFile {
    return new ClientFile(super.emptyEntity(EntityTypes.ClientFile));
  }
}
