import { Injectable, Injector } from '@angular/core';
import { WarpEntityServiceCache, WarpEntityCacheFactoryService } from '@ripple/services';
import { EntityTypes, Client, ClientFile, ClientFileTemplate, ClientFileTemplateSession } from '../../models';
import { EntityFilter, WarpEntity, IWarpEntityInputObject, FilterOperator } from '@ripple/models';
import { first, filter, tap, map } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientFileServiceCache extends WarpEntityServiceCache<ClientFile> {
  templateService: WarpEntityServiceCache<ClientFileTemplate>;
  sessionTemplateService: WarpEntityServiceCache<ClientFileTemplateSession>;
  formService: WarpEntityServiceCache<WarpEntity>;

  constructor(injector: Injector) {
    super(injector, ClientFile, EntityTypes.ClientFile, 100000);
    const cacheFactory = this.injector.get(WarpEntityCacheFactoryService);
    this.templateService = cacheFactory.get(EntityTypes.ClientFileTemplate) as WarpEntityServiceCache<ClientFileTemplate>;
    this.sessionTemplateService = cacheFactory.get(EntityTypes.ClientFileTemplateSession) as WarpEntityServiceCache<ClientFileTemplateSession>;
    this.formService = cacheFactory.get(EntityTypes.ClientFileTemplateForm);
  }

  getFilesForClient(client: Client | number, fileType?: number) {
    this.messageService.add(this.typeName, 'Loading files for client', client);
    const clientId = client instanceof Client ? client.entityId : client;
    const filter = EntityFilter.Advanced({ clients_lid: clientId })
      .orderBy('nextsession', 'asc')
      .orderBy('dateopened', 'desc');

    if (fileType) filter.Advanced({ clientfiletemplate_lid: fileType });

    return this.initQuery(filter);
  }

  getGroupFiles() {
    this.messageService.add(this.typeName, 'Loading group files');
    const filter = EntityFilter.Advanced({ isGroupFile: 'Y' });
    return this.initQuery(filter);
  }

  getFileTemplate(clientFile: ClientFile) {
    return this.templateService.getById(clientFile.linkedProperties.clientfiletemplate.id).pipe(first());
  }

  getSessionTemplatesForClientFile(clientFile: ClientFile): Observable<ClientFileTemplateSession[]> {
    // const filter = EntityFilter.Advanced({
    //   parentId: clientFile.linkedProperties.clientfiletemplate.id
    // });
    const parentClientFileTemplateId = clientFile.linkedProperties.clientfiletemplate.id;

    // get all clientFileTemplateSessions and current sessions
    return this.sessionTemplateService.initQuery(EntityFilter.None)
        .getPage(0)
        .pipe(
          tap(sessionTemplates => console.log('got all templates', sessionTemplates)),
          map(sessionTemplates => sessionTemplates.filter(t => t.parentIds.includes(parentClientFileTemplateId)))
        )
  }
}
