import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { platformBrowser } from '@angular/platform-browser';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { environment as rippleEnvironment } from '@ripple/environment';
import '@angular/compiler';

// import { routes } from './app/_core';
// environment.routes = routes;

rippleEnvironment.__mergeEnvironment(environment);

if (environment.production) {
  enableProdMode();
  platformBrowser().bootstrapModule(AppModule)
   .catch(err => console.error(err));
} else {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
}





