<ccasa-screen-size-detector></ccasa-screen-size-detector>
<ripple-loading-box [loading]="hideAllContent()">
  <div *ngIf="!hideAllContent()" class="app-container">
    <ripple-menu
      logoPath="{{logoPath}}"
      logoPrintPath="{{logoPrintPath}}"
      brandName="{{brandName}}"
      [sectionItems]="sections"
      [currentView]="currentView"
      [userMenuItems]="userMenuItems"
      [viewAllNotificationLink]="'/settings/notification'"
      *ngIf="layoutSettings.showMenuBar"
      [showPrimaryMenu]="layoutSettings.showMenuBarPrimaryMenu"
      [showProfileHelpMenu]="layoutSettings.showMenuBarProfileHelpMenu"
      [showNotifications]="layoutSettings.showMenuBarNotifications"
      [showSignalRCheck]="false">
    </ripple-menu>
    
    <div [ngClass]="{ 'app-main': layoutSettings.showMenuBar, 'app-main--no-header': !layoutSettings.showMenuBar }">
      <!-- <router-outlet (activate)="onRouterOutletLoad($event)"></router-outlet> -->
      <router-outlet></router-outlet>
    </div>

    <ripple-footer-bar *ngIf="layoutSettings.showFooterBar" [copyrightName]="'CCASA'"></ripple-footer-bar>
  </div>
</ripple-loading-box>