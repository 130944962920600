import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { InternalCookieService, MessageService, CookieType, AuthService } from '@ripple/services';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class FancyRoutingService {
  private readonly DEFAULT_ROUTE_COOKIE = 'default-route';
  public enableHelpline = environment.enableHelpline;

  private get userToken() {
    return this.auth.getToken();
  }
  private get userName() {
    return this.auth.loadedUser && this.auth.loadedUser.properties.email || 'unknown';
  }

  constructor(
    private messageService: MessageService,
    private router: Router,
    private auth: AuthService,
    private activeRoute: ActivatedRoute,
    private cookieService: InternalCookieService
  ) { }

  set defaultRoute(route: string) {
    if (typeof route !== 'string') // null, undefined, or something weird
      this.cookieService.dropCookie(this.DEFAULT_ROUTE_COOKIE);

    this.cookieService.setCookie(this.DEFAULT_ROUTE_COOKIE, route);
  }

  get defaultRoute() {
    return this.cookieService.getCookie(this.DEFAULT_ROUTE_COOKIE);
  }

  tryDefault() {
    if ( /^true$/i.test(this.activeRoute.snapshot.paramMap.get('fromHelpLine')))
      this.defaultRoute = undefined;

    if (this.defaultRoute) this.route(this.defaultRoute);
  }

  selectSystem(route: string) {
    this.route(this.defaultRoute = route);
  }

  route(route: string) {
    // '/helpline/', '_self'); //
    if (environment.enableHelpline && route === '/helpline/')
      window.open(`${environment.helplineUrl.replace(/\/+$/g, '')}/token/${this.userToken}?username=${this.userName}`, '_self');
    else
      this.router.navigate([route]);
  }

}
