<div class="layout-wrapper" *ngIf="loggedIn">
  <div class="layout-main">
    <div class="system-select">
      <div class="p-grid p-justify-center p-grid-layout">

        <div class="p-col p-flex p-justify-center">
          <div class="system-select-card system-select-card--clinical" (click)="selectSystem('/clinical/')" role="button">
            <div class="system-select-card__title">Clinical</div>
          </div>
        </div>

        <div class="p-col p-flex p-justify-center">
          <div class="system-select-card system-select-card--education" (click)="selectSystem('/education/')" role="button">
            <div class="system-select-card__title">Education</div>
          </div>
        </div>

        <ng-container *ngIf="fancyRouting.enableHelpline">
          <div class="p-col p-flex p-justify-center">
            <div class="system-select-card system-select-card--helpline" (click)="selectSystem('/helpline/')" role="button">
              <div class="system-select-card__title">Help Line</div>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
</div>
