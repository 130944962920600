import { Injectable, Injector } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { WarpEntityServiceCache, WarpEntityCacheFactoryService } from '@ripple/services';
import { EntityTypes, Client, User } from '../../models';
import { first, map, exhaustMap } from 'rxjs/operators';
import { SessionServiceCache } from './session.service';

/**
 * Keeps track of a selected user as well as the list of clients
 */

@Injectable({
  providedIn: 'root',
})
export class ClientServiceCache extends WarpEntityServiceCache<Client> {
  private _selectedClient: BehaviorSubject<Client>;
  private _selectedClientExisting: Subject<boolean>;
  private _store: Client[] = [];
  private _clinicianHistoryCache: Map<number, Observable<User[]>>;
  private _selectedClientSub: Subscription;
  // private _sessionService: SessionServiceCache;

  constructor(injector: Injector) {
    super(injector, Client, EntityTypes.Client);
    this._selectedClient = new BehaviorSubject<Client>(Client.notFound);
    this._selectedClientExisting = new Subject<boolean>();
    this._clinicianHistoryCache = new Map();
    const serviceFactory = injector.get(WarpEntityCacheFactoryService);
    const sessionService = serviceFactory.get(EntityTypes.Session) as SessionServiceCache;
    sessionService.onEveryChange
      .pipe(exhaustMap((o) => sessionService.get(o.entityId)))
      .subscribe((session) =>
        (session.properties.clients || []).forEach((client) => this._clinicianHistoryCache.delete(client.id))
      );
  }

  get clients() {
    return Array(...this.entities.value.values()).sort();
  }
  get selected() {
    return this._selectedClient;
  }
  get selectedExisting() {
    return this._selectedClientExisting;
  }

  set select(c: Client | number) {
    if (this._selectedClient.value.entityId === (c instanceof Client ? c.entityId : c)) return;
    if (c instanceof Client) this._selectedClient.next(c);
    else if (c === -1) {
      c = Client.notFound;
      c.mnemonic = 'Add new User';
      this._selectedClient.next(c);
    } else {
      if (this._selectedClientSub) this._selectedClientSub.unsubscribe();
      this._selectedClientSub = this.get(c)
        .pipe(first())
        .subscribe((c2) => {
          if (c2) this._selectedClient.next(c2);
        });
      // check existing
      this.checkExisting(c).then((existing) => {
        this._selectedClientExisting.next(existing);
      });
    }
  }

  getClinicianHistory(clientId: number) {
    console.log('history cache', this._clinicianHistoryCache);
    if (!this._clinicianHistoryCache.has(clientId)) {
      this._clinicianHistoryCache.set(
        clientId,
        this.warpService
          ._get('/api/ccasa/overview/clinician-history/' + clientId, 'Get Client Clinician History')
          .pipe(map((m) => m.map((e) => new User(e))))
      );
    }
    return this._clinicianHistoryCache.get(clientId);
  }

  getAssignedClinicians(clientId: number): Observable<User[]> {
    return this.warpService
      ._get('/api/ccasa/overview/assigned-clinicians/' + clientId, 'Get Client Assigned Clinicians')
      .pipe(map((m) => m.map((e) => new User(e))));
  }

  getDormantClients(): Observable<Client[]> {
    return this.warpService
      ._get('/api/ccasa/overview/dormant-clients', 'Get Dormant Clients')
      .pipe(map((m) => m.map((e) => new Client(e))));
  }
}
