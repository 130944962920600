import { util } from '@ripple/core';
import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../entity-types';
import { Person } from './_person';
import * as moment from 'moment';

declare var require;

const chooseYN = (name) => util.chooseFrom([{ [name]: 'Y', [`${name}_cfcid`]: 736}, { [name]: 'N', [`${name}_cfcid`]: 737}]) as object;

export const ClientType = {
  CLINICAL: { id: 1998, optionName: 'Clinical' },
  EDUCATION: { id: 1999, optionName: 'Education' },
  PACES: { id: 2001, optionName: 'PACES' },
  CSART: { id: 2225, optionName: 'CSART' },
  CRISIS: { id: 2800, optionName: 'CRISIS' }
}

export class Client extends WarpEntity implements Person {
  static notFound: Client = new Client({
    id: '',
    entityId: -1,
    img: 'assets/img/unknownUser.png',
    mnemonic: 'Loading Client',
    properties: {
      anonymous: 'Y',
      anonymous_cfcid: 736
    }
  });

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.Client;
  }

  get displayName(): string {
    const fl = this.fullName;
    const dn = (this.isAnonymous || !fl) ? this.mnemonic : fl;
    return `${dn}`; // [${this.id}]`;
  }

  get fullName(): string {
    const fl = `${this.firstname} ${this.lastname}`;
    return fl === ' ' || fl === 'undefined undefined' ? undefined : fl;
  }

  get isAnonymous(): boolean {
    return this.anonymous && (this.anonymous.id === 736 || this.properties.anonymous_cfcid === '736');
  }

  get dateOfBirth(): Date {
    return this.properties['birth date'] ? new Date(this.properties['birth date'] + ' 00:00') : null;
  }

  get dob(): moment.Moment {
    return this.properties['birth date'] ? moment(this.dateOfBirth) : null;
  }

  get generatedID(): string {
    if (this.isAnonymous) return this.properties['id'] = `A_${String(this.entityId).padStart(8, '0')}`;

    const initial = (name: string) => name ? name.substring(0, 1).toUpperCase() : '';
    const initials = initial((this.firstname || this.lastname) as string) + initial((this.lastname || this.firstname) as string);
    const num = this.dob ? this.dob.format('YYYYMMDD') : String(this.entityId).padStart(8, '0');

    return this.properties['id'] = `${initials || 'A_'}${num}`;
  }

  id: string | number;
  img = 'assets/img/anonymousUser.png';

  toString() {
    return (this.isAnonymous ? 'Anonymous' : ' ') + this.displayName;
  }

  static new(anonymous: boolean = true): Client {
    const props = {
      mnemonic: `${util.color()} ${util.animal()}`,
      id: `A_${util.randomInt(10000000, 99999999)}`,
      ...([{ anonymous: 'Y', anonymous_cfcid: 736}, { anonymous: 'N', anonymous_cfcid: 737}])[anonymous ? 0 : 1],
    };
    return new Client({
      entityTypeId: 627,
      properties: props,
    });
  }

  static random(anonymous: boolean = true): Client {
    const props = {
      mnemonic: `${util.color()} ${util.animal()}`,
      id: `A_${util.randomInt(10000000, 99999999)}`,
      ...([{ anonymous: 'Y', anonymous_cfcid: 736}, { anonymous: 'N', anonymous_cfcid: 737}])[anonymous ? 0 : 1],
      ...chooseYN('assistancerequired'),
      ...chooseYN('translatorrequired'),
    };
    if (props.anonymous === 'N')
      Object.assign(props, this._randomUserInfo(props['translatorrequired'] === 'N'));
    return new Client({
      entityTypeId: 627,
      properties: props,
    });
  }

  private static _randomUserInfo(englishSpeaker: boolean) {
    const initial = (name: string) => name.substring(0, 1).toUpperCase();
    const first = util.names({ first: true});
    const last  = util.names({ last: true});
    const bday  = util.randomMoment();

    return {
      'first name':           first,
      'last name':            last,
      id:                     initial(first) + initial(last) + bday.format('YYYYMMDD'),
      'birth date':            bday.format('YYYY-MM-DD'),
      telephone1:             '1234567890',
      telephone2:             '',
      email:                  'keenan@ripplegroup.ca',
      address:                `${util.randomInt(1, 1000)} ${util.titlecase(util.lorem.generateWords(4))}`,
      identifiesas:           util.chooseFrom(['Male', 'Trans Male', 'Female', 'Trans Female', 'Non-Binary', 'Other']),
      pronouns:               util.chooseFrom(['He / Him', 'She / Her', 'Them / They']),
      referredby:             util.names(),
      language:               util.lang(englishSpeaker ? 'en' : null),
      guardian:               util.chooseFrom([util.names({ first: true }) + last, '']),

      ...chooseYN('allowcallingfromccasa'),
      ...chooseYN('allowguardianbookings'),
      ...chooseYN('allowsmsnotifications'),
      ...chooseYN('allowemailnotifications'),
    };
  }
}


