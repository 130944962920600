import { Injectable, Injector } from '@angular/core';
import { WarpEntityServiceCache, WarpEntityCacheFactoryService } from '@ripple/services';
import { EntityTypes, EventFolder, EventTemplate } from '../../models';
import { EntityFilter, FilterOperator, WarpEntity } from '@ripple/models';
import { MenuItem, TreeNode } from 'primeng/api';
import { environment as ccmEnvironment } from 'subscribers/ccasa-emr/src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class EventFolderServiceCache extends WarpEntityServiceCache<EventFolder> {

  constructor(injector: Injector) {
    super(injector, EventFolder, EntityTypes.EventFolder, 1000);
    const cacheFactory = this.injector.get(WarpEntityCacheFactoryService);
  }

  generateTreeNodeFromEventFolder(eventFolders: EventFolder[], expanded = false, makeFolderOfFolderUnselectable = false): TreeNode[] {
    return eventFolders.filter(ef => !ef.parentfolder).map(ef =>
      this.generateTreeNodeFromEventFolderInternal(ef, eventFolders, expanded, false,
        ef.entityId === 179227 || ef.entityId === ccmEnvironment.eventTemplateFolder, makeFolderOfFolderUnselectable ));
  }

  generateTreeNodeFromEventFolderInternal(eventFolder: EventFolder, eventFolders: EventFolder[],
    expanded = false, noChildren = false, isTemplateFolder = false, makeFolderOfFolderUnselectable = false): TreeNode {
    eventFolder.isTemplateFolder = isTemplateFolder;
    return {
      label: eventFolder.name,
      data: eventFolder,
      expandedIcon: 'pi pi-folder-open event-folder',
      collapsedIcon: 'pi pi-folder event-folder',
      leaf: false,
      expanded,
      selectable: makeFolderOfFolderUnselectable ? eventFolders.filter(ef => ef.parentfolder?.id === eventFolder?.entityId).length === 0 : true,
      children: noChildren ? [] : eventFolders
                  .filter(ef => ef.parentfolder?.id === eventFolder?.entityId)
                  .map(ef => this.generateTreeNodeFromEventFolderInternal(ef, eventFolders, expanded, noChildren, isTemplateFolder, makeFolderOfFolderUnselectable))
    };
  }

  generateTreeNodeFromTemplatesAndFolders(eventFolders: (EventFolder | EventTemplate)[], expanded = false): TreeNode[] {
    return eventFolders.filter(ef => ef.entityId === 179227 || ef.entityId === ccmEnvironment.eventTemplateFolder).map(ef =>
      this.generateTreeNodeFromTemplatesAndFoldersInternal(ef, eventFolders, expanded, false));
  }

  generateTreeNodeFromTemplatesAndFoldersInternal(eventFolder: (EventFolder | EventTemplate), eventFolders: (EventFolder | EventTemplate)[],
    expanded = false, isTemplate = false): TreeNode {
    eventFolder.isTemplateFolder = true;
    return {
      label: eventFolder.name,
      data: eventFolder,
      expandedIcon: isTemplate ? null : 'pi pi-folder-open  event-folder',
      collapsedIcon: isTemplate ? null : 'pi pi-folder event-folder',
      icon: isTemplate ? 'pi pi-file' : null,
      leaf: isTemplate,
      expanded,
      selectable: isTemplate,
      children: isTemplate ? [] : eventFolders
                  .filter(ef => (ef.parentfolder && ef.parentfolder.id === eventFolder.entityId) || (ef.eventfolder && ef.eventfolder.id === eventFolder.entityId))
                  .map(ef => this.generateTreeNodeFromTemplatesAndFoldersInternal(ef, eventFolders, expanded, ef.entityTypeId === 641))
    };
  }

  findChild(target: TreeNode, treeNode: TreeNode, path: MenuItem[], nodeSelect: (event, parentComponent)=>void, parentComponent): boolean {
    path.push({
      label: treeNode.label,
      icon: treeNode.data ? this.getNodeIcon(treeNode.data.entityTypeId) : '',
      command: () => nodeSelect({ node: treeNode }, parentComponent)
    });
    treeNode.expanded = true;
    if (treeNode.children.length === 0) {
      path.pop();
      treeNode.expanded = false;
      return false;
    } else {
      const find = treeNode.children.find(node => node.data.entityId === target.data.entityId);
      if (find) {
        path.push({
          label: find.label,
          icon: find.data ? this.getNodeIcon(find.data.entityTypeId) : '',
          command: () => nodeSelect({ node: find }, parentComponent)
        });
        find.expanded = true;
        return true;
      } else {
        let inChild = false;
        for (const child of treeNode.children) {
          if (this.findChild(target, child, path, nodeSelect, parentComponent)) {
            inChild = true;
            break;
          }
        }
        if (!inChild) {
          path.pop();
          treeNode.expanded = false;
        }
        return inChild;
      }
    }
  }

  getNodeIcon(entityType: EntityTypes) {
    switch(entityType) {
      case EntityTypes.EventFolder:
        return 'pi pi-folder event-folder';
      case EntityTypes.EventTemplate:
        return 'pi pi-file';
      case EntityTypes.EducationEvent:
        return 'pi pi-calendar';
    }
  }
}
