import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../entity-types';
import { BehaviorSubject } from 'rxjs';

export const MicrositeFormType = {
  Clinical: { id: 2041, name: 'Clinical' },
  PACES: { id: 2042, name: 'PACES' },
  CSART: { id: 2043, name: 'CSART' },
  Education_Disclosure: { id: 2044, name: 'Education Disclosure' },
  CRISIS: { id: 2801, name: 'CRISIS' },
};

export class FilledForm extends WarpEntity {
  formData: BehaviorSubject<WarpEntity>;
  wasOptional = false;

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.FilledForm;
    this.formData = new BehaviorSubject(null);
  }

  static empty(): FilledForm {
    return new FilledForm(super.emptyEntity(EntityTypes.FilledForm));
  }

  get isCompleted() {
    return this.formstatus && this.formstatus.id === 1277;
  }

  get isStarted() {
    return this.isCompleted || (this.formstatus && this.formstatus.id === 1276);
  }

  get isForReview() {
      return this.properties.formstatus && this.properties.formstatus[0].id === 2899;
  }

}
