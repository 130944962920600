import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../../entity-types';
import { util } from '@ripple/core';

export class EventType extends WarpEntity {
  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.EventType;
    // get rgb color
    this.bgColor = this.properties.color ? this.properties.color : '#000000';
    const colorArr = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.bgColor);
    this.rgbColor = colorArr ? {
      r: parseInt(colorArr[1], 16),
      g: parseInt(colorArr[2], 16),
      b: parseInt(colorArr[3], 16)
    } : null;
    // get text color
    const hsp = Math.sqrt(
      0.299 * (this.rgbColor.r * this.rgbColor.r) +
      0.587 * (this.rgbColor.g * this.rgbColor.g) +
      0.114 * (this.rgbColor.b * this.rgbColor.b)
    );
    if (hsp > 127.5) this.textColor = 'var(--secondary-text)'; // bg-light
    else this.textColor = 'var(--primary-text)'; // bg-dark
  }

  static empty(): EventType {
    return new EventType(super.emptyEntity(EntityTypes.EventType));
  }

  static random(): EventType {
    const e = EventType.empty();
    e.properties['name'] = `${util.names({ seed: new Date() })}`;
    e.properties['color'] = util.color('name');
    return e;
  }
}
