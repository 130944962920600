import { WarpEntity } from '@ripple/models';
import { EntityTypes } from '../entity-types';


export class CCMAggregateReportStat extends WarpEntity {
  //entityTypeId: EntityTypes;
  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.CCMAggregateReportStat;
  }

  static empty(): CCMAggregateReportStat {
    return new CCMAggregateReportStat(super.emptyEntity(EntityTypes.CCMAggregateReportStat));
  }
}
