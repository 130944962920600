import { Injectable, Injector } from '@angular/core';
import { MessageService, WarpEntityServiceCache } from '@ripple/services';
import { HttpClient } from '@angular/common/http';
import { WarpEntity } from '@ripple/models';
import { Event, EntityTypes, EducationEvent } from '../../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventService extends WarpEntityServiceCache<(WarpEntity & Event)> {

  constructor(injector: Injector) {
    super(injector, WarpEntity, EntityTypes.TimeSlot);
  }

  batch(reqBody) {
    return this.warpService._post('/api/ccasa/event/batch', reqBody, 'batch');
  }

  sendEventInvitation(reqBody) {
    return this.warpService._post('/api/ccasa/event/eventInvite', reqBody, 'eventInvite');
  }

  getPendingPaperwork(clientType = '') {
    return this.warpService
      ._get('/api/ccasa/overview/pending-paperwork-education')
      .pipe(map((m) => m.map((e) => new EducationEvent(e))));
  }

}
